import styled, { css } from 'styled-components';
import LinkComponent from '@components/link';
import Image from '@components/image';
import { defaultTheme } from '@styles/theme.styled';

interface BlockProps {
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
}

interface ContainerProps {
  backgroundAlignment?: string;
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, backgroundAlignment = 'hidden' }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 4rem;
    padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '8rem'};

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      row-gap: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '4rem'};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      row-gap: 2rem;
      padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '3rem'};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '4rem'};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    text-align: center;
    max-width: 54rem;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
      max-width: 100%;
    }
  `}
`;

export const Grid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      row-gap: 4rem;
      column-gap: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: 1fr 1fr;
      flex-direction: column;
      row-gap: 3rem;
      column-gap: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      row-gap: 3rem;
      column-gap: 4rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: 1fr;
      row-gap: 2.5rem;
    }
  `}
`;

export const StyledLinkComponent = styled(LinkComponent)<BlockProps>`
  ${({ theme, backgroundColor = 'white' }) => css`
    width: fit-content;
    margin: ${theme.padding.md} auto 0;

    ${(backgroundColor === 'dark' || backgroundColor === 'primary') &&
    css`
      a {
        color: white;
      }
    `};
  `}
`;

export const BlockImage = styled(Image)`
  div,
  img,
  picture {
    object-position: left center;
  }
`;

export const Block = styled.div<BlockProps>`
  ${({ theme, backgroundColor = 'white' }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      row-gap: 1.25rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      row-gap: 1rem;
    }

    ${(backgroundColor === 'dark' || backgroundColor === 'primary') &&
    css`
      ${StyledLinkComponent}, ${StyledLinkComponent} a {
        color: ${theme.colors.white};
      }
    `}
  `}
`;

const patternDescription = css`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      line-height: 1.25;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      line-height: 1.5;
    }
  `}
`;

export const BlockText = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      row-gap: 0.5rem;
    }

    ${patternDescription}
  `}
`;
