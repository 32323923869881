import React from 'react';
import Wrapper from '@components/wrapper.styled';
import generateId from '@helpers/generate-id';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultTheme } from '@styles/theme.styled';
import Text from '@components/text';
import Section from '@components/section';
import { ItemContainer, StyledGrid, ItemText, Content, TitleContent } from './stat-blocks';

const StatBlocksIdGenerator = generateId();

export interface StatBlocksProps {
  items: StatBlocksItemProps[];
  backgroundAlignment?: string;
  displayBorder?: boolean;
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor?: keyof typeof defaultTheme.backgroundColors;
  title?: string;
  description?: RichTextBlock[];
}

export interface StatBlocksItemProps {
  stat?: string;
  description?: {
    richText: RichTextBlock[];
  };
}

const StatBlocks = ({
  items,
  title,
  description,
  displayBorder,
  backgroundColor = 'white',
  backgroundAlignment = 'hidden',
  outerbackgroundColor = 'white',
}: StatBlocksProps): JSX.Element => {
  return (
    <Section
      backgroundColor={backgroundColor}
      displayBorder={displayBorder}
      outerbackgroundColor={outerbackgroundColor}
      backgroundAlignment={backgroundAlignment}
    >
      <Wrapper>
        <Content>
          <TitleContent>
            {title && (
              <Text type="title" tag="h2" stringText={title} fontSize="heading2" margin="none" />
            )}
            {description && (
              <Text type="subheading" richText={description} fontSize="large" margin="none" />
            )}
          </TitleContent>
          <StyledGrid itemsPerRow={3}>
            {items.map((item) => {
              return (
                <ItemContainer key={StatBlocksIdGenerator.next().value}>
                  <ItemText>
                    {item.stat && (
                      <Text
                        tag="p"
                        fontColor="secondary"
                        type="title"
                        fontSize="heading1"
                        stringText={item.stat}
                        margin="xs"
                      />
                    )}
                    {item.description?.richText && (
                      <Text
                        type="description"
                        fontSize="body"
                        richText={item.description?.richText}
                      />
                    )}
                  </ItemText>
                </ItemContainer>
              );
            })}
          </StyledGrid>
        </Content>
      </Wrapper>
    </Section>
  );
};

export default StatBlocks;
