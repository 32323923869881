import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';
import LinkComponent from '@components/link';
import Grid from '@components/grid';

interface LinkComponentProps {
  fontColor: keyof typeof defaultTheme.colors;
}

export const StyledLink = styled(LinkComponent)<LinkComponentProps>`
  ${({ theme }) => {
    const { mobile, desktop } = theme.fontSize.body;
    return css`
      margin: 0;
      ${generateScales('font-size', mobile, desktop, theme)}
      font-weight: 500;
      width: fit-content;
    `;
  }}
`;

export const TextContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.5rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    text-align: center;
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
    }
  `}
`;

export const StyledGrid = styled(Grid)`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      row-gap: 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: flex;
      flex-wrap: wrap;
      row-gap: 1rem;
      justify-content: flex-start;
    }
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 5rem;
    margin: 0 auto;
    padding: 1.5rem;
    max-width: 5rem;
    background-color: ${theme.colors.secondary};
    border-radius: 100%;
    width: 100%;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      height: 4rem;
      max-width: 4rem;
      padding: 1.125rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      height: 3rem;
      max-width: 3rem;
      padding: 0.75rem;
    }
  `}
`;

export const Container = styled.div<{ isLink: boolean }>`
  ${({ theme, isLink }) => css`
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: ${theme.backgroundColors.light};
    border-radius: 1rem;
    padding: 3rem 2rem;
    transition: transform 0.125s ease-in-out;

    ${isLink &&
    css`
      cursor: pointer;
      &:hover {
        transform: scale(1.0375);

        ${StyledLink} a {
          background-size: 100% 0.15rem;
        }
      }
    `};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      text-align: left;
      width: 100%;
      flex-direction: row;
      column-gap: 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      padding: 2rem 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      border-radius: 1rem;
      flex-direction: row;
      padding: 2rem 1rem;
      text-align: left;
      column-gap: 1.25rem;
    }
  `}
`;
