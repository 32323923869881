import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import Image from '@components/image';
import LinkComponent from '@components/link';

export const GradientWrapper = styled.div<{ layoutType?: string }>`
  ${({ theme, layoutType }) => css`
    ${generateScales('padding', '1rem', '1.5em', theme)}
    background: linear-gradient(90deg, ${theme.colors.accentSecondary} 0%,
      ${theme.colors.blueSecondary} 25%, ${theme.colors.primaryLight} 75%, ${theme.colors
      .primary} 100%);
    border-radius: 1rem;

    ${layoutType === 'Column' &&
    css`
      max-width: 63.5rem;
      width: 100%;
      margin: 0 auto;
    `}
  `}
`;

export const Container = styled.div<{ layoutType?: string }>`
  ${({ theme, layoutType }) => css`
    margin: 0 auto;
    border-radius: 0.5rem;
    background-color: ${theme.backgroundColors.light};

    ${layoutType === 'Row'
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3rem;
          column-gap: 4rem;

          @media (max-width: ${theme.breakpoints.xxl}) {
            column-gap: 3rem;
          }

          @media (max-width: ${theme.breakpoints.lg}) {
            padding: 2rem;
            column-gap: 2rem;
            align-items: flex-start;
          }

          @media (max-width: ${theme.breakpoints.md}) {
            flex-direction: column;
            row-gap: 2rem;
            align-items: center;
            padding: 2rem 1.5rem;
            text-align: center;
          }

          @media (max-width: ${theme.breakpoints.sm}) {
            padding: 2rem;
            align-items: flex-start;
            row-gap: 1.5rem;
            text-align: left;
          }
        `
      : css`
          display: block;
          ${generateScales('padding-top', '1rem', '3rem', theme)};
          ${generateScales('padding-bottom', '1rem', '3rem', theme)};
          ${generateScales('padding-left', '1rem', '6rem', theme)};
          ${generateScales('padding-right', '1rem', '6rem', theme)};
        `}
  `}
`;

export const ImageContainer = styled(Image)`
  ${({ theme }) => css`
    max-width: 14rem;
    max-height: 14rem;
    height: 100%;
    width: 100%;

    @media (max-width: ${theme.breakpoints.lg}) {
      max-width: 12rem;
      max-height: 12rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-height: 8rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: none;
      max-height: 6rem;

      img {
        object-position: left;
      }
    }
  `}
`;

interface ContentProps {
  hasImage: boolean;
}

export const Content = styled.div<ContentProps>`
  ${({ theme, hasImage }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 4rem;

    ${hasImage === false
      ? css`
          padding: 0 2rem;

          @media only screen and (max-width: ${theme.breakpoints.lg}) {
            flex-direction: column;
            text-align: center;
            row-gap: 2rem;
            padding: 0;
          }

          @media only screen and (max-width: ${theme.breakpoints.sm}) {
            align-items: flex-start;
            text-align: left;
          }
        `
      : css`
          flex-direction: column;
          row-gap: 2rem;
          align-items: flex-start;

          @media only screen and (max-width: ${theme.breakpoints.xl}) {
            flex-direction: column;
            row-gap: 2rem;
          }

          @media only screen and (max-width: ${theme.breakpoints.lg}) {
            row-gap: 1.5rem;
          }

          @media only screen and (max-width: ${theme.breakpoints.md}) {
            align-items: center;
          }

          @media only screen and (max-width: ${theme.breakpoints.sm}) {
            align-items: flex-start;
            text-align: left;
          }
        `}
  `}
`;

export const TextContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      align-items: center;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      align-items: flex-start;
    }
  `}
`;

export const LinkButton = styled(LinkComponent)`
  ${({ theme }) => css`
    background-color: ${theme.colors.accent};
    border: 1px solid ${theme.colors.accent};
    color: ${theme.colors.primary};

    :hover {
      background-color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `}
`;
