import React, { useEffect, useState, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import { defaultTheme } from '@styles/theme.styled';
import { CookieConsentWrapper, CookieText, StyledButton } from './cookie.styled';

export interface CookieInterface {
  prismicGeneralConfig: {
    data: {
      cookie_text?: {
        richText: RichTextBlock[];
      };
      favicon?: {
        url?: string;
      };
    };
  };
}

interface CookieProps {
  offsetAmountSetter: React.Dispatch<React.SetStateAction<number>>;
  bgColor?: keyof typeof defaultTheme.backgroundColors;
}

const Cookie = ({ offsetAmountSetter, bgColor }: CookieProps) => {
  const location = useLocation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [innerElement, setInnerElement] = useState<HTMLDivElement | null>(null);

  const {
    prismicGeneralConfig: {
      data: { cookie_text },
    },
  }: CookieInterface = useStaticQuery(
    graphql`
      query CookieQuery {
        prismicGeneralConfig {
          ...GeneralConfigFragment
        }
      }
    `
  );

  useEffect(() => {
    const target = wrapperRef.current;
    const targetHeight = target?.clientHeight;
    offsetAmountSetter(targetHeight || 0);
  }, [wrapperRef, innerElement]);

  useEffect(() => {
    const consetValue = getCookieConsentValue('cookie-conset-prompt');
    const gaValue = Cookies.get('gatsby-gdpr-google-analytics');
    if (consetValue === undefined && gaValue === undefined) {
      offsetAmountSetter(0);
      Cookies.set('gatsby-gdpr-google-analytics', 'true', { expires: 365 });
      initializeAndTrack(location);
    }
  }, []);

  return (
    <CookieConsentWrapper ref={wrapperRef} bgColor={bgColor}>
      {/* @ts-expect-error Server Component */}
      <CookieConsent
        location="bottom"
        buttonId="test"
        buttonText="Got it"
        containerClasses="consent-container"
        contentClasses="consent-content"
        buttonWrapperClasses="consent-btn-wrapper"
        disableStyles
        enableDeclineButton
        declineButtonText="Decline"
        cookieName="cookie-conset-prompt"
        ButtonComponent={StyledButton}
        onDecline={() => {
          offsetAmountSetter(0);
          Cookies.remove('gatsby-gdpr-google-analytics');
        }}
        onAccept={() => {
          offsetAmountSetter(0);
          Cookies.set('gatsby-gdpr-google-analytics', 'true', { expires: 365 });
          initializeAndTrack(location);
        }}
      >
        {!!cookie_text?.richText && (
          <div ref={setInnerElement}>
            <CookieText type="description" richText={cookie_text?.richText} />
          </div>
        )}
      </CookieConsent>
    </CookieConsentWrapper>
  );
};

export default Cookie;
