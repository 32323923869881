import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Image, { ImageProps } from '@components/image';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import generateId from '@helpers/generate-id';
import Section from '@components/section';
import getLinkConfig, { LinkConfigType } from '@helpers/get-link-config';
import {
  ImageContainer,
  Container,
  StyledLink,
  StyledGrid,
  TextContainer,
  Content,
} from './promotions.styled';

const PromotionsIdGenerator = generateId();

export interface PromotionsProps {
  items: PromotionsPropsItem[];
  itemsPerRow?: number;
}

export interface PromotionsPropsItem {
  image?: ImageProps;
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  link_label?: string;
  link?: {
    url?: string;
    uid?: string;
  };
}

const Promotions = ({ items, itemsPerRow = 3 }: PromotionsProps): JSX.Element => {
  const onLinkClick = (e: React.MouseEvent<HTMLDivElement>, { url }: LinkConfigType) => {
    e.stopPropagation();

    if (url) {
      window.open(url, '_self');
    }
  };

  return (
    <Section backgroundColor="white">
      <Wrapper>
        <Content>
          <Text
            type="title"
            tag="h2"
            fontSize="heading3"
            stringText="Flexible Solutions"
            margin="lg"
          />
          <StyledGrid itemsPerRow={itemsPerRow}>
            {items?.map(({ image, title, description, link_label, link }) => {
              const linkConfig = getLinkConfig(link);

              return (
                <Container
                  key={PromotionsIdGenerator.next().value}
                  isLink={!!linkConfig.url}
                  onClick={(e) => onLinkClick(e, linkConfig)}
                >
                  {image?.url && (
                    <ImageContainer>
                      <Image url={image?.url} alt={image?.alt || ''} />
                    </ImageContainer>
                  )}
                  <TextContainer>
                    {title?.text && (
                      <Text
                        tag="h3"
                        fontColor="primary"
                        type="title"
                        fontSize="heading6"
                        stringText={title.text}
                        margin="none"
                      />
                    )}
                    {description?.richText && (
                      <Text
                        type="description"
                        richText={description?.richText}
                        fontColor="dark"
                        margin="none"
                      />
                    )}
                    {linkConfig.url && (
                      <StyledLink
                        title={link_label || 'Learn more'}
                        url={linkConfig.url}
                        fontColor="secondary"
                        isExternal={linkConfig.isExternal}
                        type="text"
                      />
                    )}
                  </TextContainer>
                </Container>
              );
            })}
          </StyledGrid>
        </Content>
      </Wrapper>
    </Section>
  );
};

export default Promotions;
