import React from 'react';
import { RichText } from 'prismic-reactjs';
import { TextComponentProps } from '@components/text/text-props.type';
import linkResolver from '@helpers/linkResolver';
import { StyledDescription } from './description.styled';

const Description = ({
  fontColor = 'dark',
  richText,
  fontSize = 'body',
  className,
  margin,
}: TextComponentProps): JSX.Element => (
  <StyledDescription
    $fontSize={fontSize}
    $fontColor={fontColor}
    className={className}
    $margin={margin}
  >
    <RichText render={richText} linkResolver={linkResolver} />
  </StyledDescription>
);

export default Description;
