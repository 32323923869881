import Image from '@components/image';
import styled, { css, keyframes } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import Text from '@components/text';

const fadeIn = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 0;
    transform:translateX(4rem);
  }
  51% {
    opacity: 0;
    transform:translateX(-4rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const StyledHead = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    gap: 4rem;
    margin: 0 auto;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 2rem;
      margin: 0 auto;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      row-gap: 2rem;
    }
  `}
`;

export const HeadContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    ${generateScales('row-gap', '1.5rem', '4rem', theme)};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      text-align: center;
      align-self: center;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      text-align: left;
    }
  `}
`;

export const TextContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales('row-gap', '0.75rem', '1rem', theme)}
  `}
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    padding: 0.375rem;
    display: flex;
    width: fit-content;
    gap: 0.25rem;
    border: 1px solid ${theme.colors.muted};
    border-radius: 4rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      margin-top: 0;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      min-width: unset;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      gap: 0;
    }
  `}
`;

export const StyledButton = styled.button<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    padding: 0 1.5rem;
    height: 3rem;
    ${generateScales('height', '2.5rem', '3rem', theme)};
    border: none;
    outline: none;
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.body.desktop, theme)};
    font-family: ${theme.fontFamily.text};
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
    white-space: nowrap;

    background-color: ${isActive ? theme.colors.primary : theme.colors.transparent};
    color: ${isActive ? theme.colors.white : theme.colors.primary};
    border-radius: ${theme.borderRadius.large};

    &:hover {
      background-color: ${isActive ? theme.colors.primary : theme.colors.lightGreen};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: 2.75rem;
      width: 100%;
      padding: 0 1rem;
    }
  `}
`;

export const ItemContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${generateScales('column-gap', '2rem', '4rem', theme)};
    ${generateScales('row-gap', '2rem', '4rem', theme)};

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
    }
  `}
`;

export const ItemText = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    max-width: 32rem;
    ${generateScales('row-gap', '1rem', '2rem', theme)};
  `}
`;

export const ImageContainer = styled.div<{ animate: boolean }>`
  ${({ animate }) => css`
    width: 100%;
    max-width: 32rem;

    ${animate &&
    css`
      animation: 0.5s ${fadeIn} ease-in-out;
    `}
  `}
`;

export const Block = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 0.75rem;
    flex-direction: row;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      gap: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: row;
      gap: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      gap: 0.75rem;
    }
  `}
`;

export const BlockDescription = styled(Text)`
  font-size: 1rem;
`;

export const TextBlock = styled.div`
  /* margin: 0.5rem 0 0; */
`;

export const StyledImage = styled(Image)`
  ${({ theme }) => css`
    width: 100%;
    height: 18rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      height: 15rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      height: 12rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      height: 12rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: 10rem;
    }
  `}
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 2.75rem;
      height: 2.75rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: 2.5rem;
      height: 2.5rem;
    }
  `}
`;

export const TabContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales('row-gap', '2rem', '4rem', theme)};
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      align-items: center;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      align-items: flex-start;
    }
  `}
`;
