import styled, { css } from 'styled-components';

export const NavWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.backgroundColors.light};
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.75rem;
    border: 1px solid ${theme.colors.muted};
    width: fit-content;
    position: absolute;
    bottom: 2rem;
    z-index: 9;
    left: 50%;
    transform: translate(-50%, -50%);

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;

export const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  column-gap: 0rem;
`;

export const UnstyleListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0;
`;

export const StyledLink = styled.a`
  ${({ theme }) => css`
    display: flex;
    font-family: ${theme.fontFamily.display};
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.darkPurple};
    font-size: 1rem;
    line-height: 100%;
    white-space: nowrap;
    text-decoration: none;
    padding: 0.5rem 0.75rem;
    border-radius: 1.75rem;

    :hover {
      color: ${theme.colors.white};
      background-color: ${theme.colors.secondary};
    }
  `}
`;

export const MobileNavWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.backgroundColors.light};
    padding: 1rem 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 1px solid ${theme.colors.darkPurple};
    column-gap: 2rem;
    width: calc(100% - 2rem);
    max-width: 24rem;
    position: absolute;
    bottom: -2rem;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    flex-direction: column;
    row-gap: 1rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: flex;
    }
  `}
`;

export const StyledSelect = styled.select`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.darkPurple};
    border-radius: 0.5rem;
    display: block;
    font: inherit;
    line-height: 1.25em;
    padding: 0.625rem 1rem 0.625rem 1rem;
    width: 100%;

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, ${theme.colors.darkPurple} 50%),
      linear-gradient(135deg, ${theme.colors.darkPurple} 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  `}
`;

export const MobileTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.darkPurple};
    font-size: 1.125rem;
    display: block;
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.darkPurple};
  `}
`;
