import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import { defaultTheme } from '@styles/theme.styled';

export const underlineTransition = css`
  // add an animated underline to text
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 0.15rem;
  transition: background-size 0.25s;
  text-decoration: none;

  :hover,
  :focus {
    background-size: 100% 0.15rem;
  }
`;

export const createTextLinkCSS = (theme: typeof defaultTheme) =>
  css`
    display: inline;
    line-height: 1.5rem;
    font-weight: 400;
    ${underlineTransition}

    font-family: ${theme.fontFamily.display};
    color: ${theme.colors.secondary};
  `;

const createButtonLinkCSS = (
  theme: typeof defaultTheme,
  $arrowSize: number,
  displayArrow: boolean
) => {
  const { mobile, desktop } = theme.inputSize.lg;
  const arrowSize = $arrowSize / 16;

  return css`
    // layout
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    // typography
    color: ${theme.colors.primary};
    font-family: ${theme.fontFamily.display};
    letter-spacing: -0.00625rem;
    ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      font-size: ${mobile.fontSize};
    }
    line-height: normal;
    font-weight: 500;
    text-decoration: none;

    // sizing
    gap: ${parseFloat(mobile.height) / 4}rem;
    width: fit-content;
    height: ${desktop.height};
    ${generateScales('height', mobile.height, desktop.height, theme)}
    ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius, theme)}
    ${displayArrow
      ? css`
          padding-right: ${(parseFloat(desktop.height) - arrowSize) / 2}rem;
          ${generateScales(
            'padding-right',
            `${(parseFloat(mobile.height) - arrowSize) / 2}`,
            `${(parseFloat(desktop.height) - arrowSize) / 2}`,
            theme
          )}
        `
      : css`
          ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
        `}


    // appearance
    background-color: ${theme.colors.accent};
    border: none;
    border-radius: ${theme.borderRadius.large};
    ${generateScales(
      'border-radius',
      `${parseFloat(mobile.height) / 2}`,
      `${parseFloat(desktop.height) / 2}`,
      theme
    )}

    // states
    :hover {
      background-color: ${theme.colors.primaryLight};
    }
  `;
};

export const StyledLinkWrapper = styled.div`
  ${({ theme }) => css`
    display: inline;
    line-height: 1.5rem;
    font-weight: 400;
    font-family: ${theme.fontFamily.display};
    color: ${theme.colors.secondary};
  `}
`;

export const StyledTextLink = styled(Link)`
  ${({ theme }) => createTextLinkCSS(theme)}
`;
export const ExternalTextLink = styled.a`
  ${({ theme }) => createTextLinkCSS(theme)}
`;

interface ButtonLinkProps {
  $arrowSize: number;
  displayArrow: boolean;
}

export const StyledButtonLink = styled(Link)<ButtonLinkProps>`
  ${({ theme, $arrowSize, displayArrow }) => createButtonLinkCSS(theme, $arrowSize, displayArrow)}
`;

export const ExternalButtonLink = styled.a<ButtonLinkProps>`
  ${({ theme, $arrowSize, displayArrow }) => createButtonLinkCSS(theme, $arrowSize, displayArrow)}
`;

export const StyledButtonLabel = styled.span`
  line-height: 1rem;
`;

export const ImageContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;
