import React from 'react';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledLabel } from './label.styled';

const Label = ({
  fontColor = 'darkPurple',
  stringText,
  className,
  fontSize = 'small',
  margin = 'xs',
}: TextComponentProps): JSX.Element => (
  <StyledLabel $fontColor={fontColor} $fontSize={fontSize} className={className} $margin={margin}>
    {stringText}
  </StyledLabel>
);

export default Label;
