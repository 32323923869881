import styled, { css, keyframes } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import Image from '@components/image';
import Grid from '@components/grid';
import { generateScales } from '@helpers/generate-media-queries';

export const SecurityGrid = styled(Grid)`
  ${({ theme }) => css`
    column-gap: 5rem;
    row-gap: 3rem;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      row-gap: 3rem;
      column-gap: 6rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      row-gap: 4rem;
      column-gap: 4rem;
    }
  `}
`;

interface PadderProps {
  backgroundAlignment?: string;
}

export const Padder = styled.div<PadderProps>`
  ${({ theme, backgroundAlignment = 'hidden' }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales('row-gap', '1.5rem', '3rem', theme)}
    padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '8rem'};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '4rem'};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '3rem'};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '4rem'};
    }
  `}
`;

interface ContainerProps {
  align?: string;
  backgroundColor: keyof typeof defaultTheme.backgroundColors;
}

export const Container = styled.div<ContainerProps>`
  ${({ align = 'center', backgroundColor = 'white', theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
    margin: 0 auto;
    text-align: ${align};

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
    }

    ${(backgroundColor === 'dark' || backgroundColor === 'primary') &&
    css`
      color: white;
    `};
  `}
`;

interface SecurtiyImageProps {
  width?: number;
  height?: number;
}

export const SecurtiyImage = styled(Image)<SecurtiyImageProps>`
  ${({ theme, width, height }) => css`
    max-height: 6rem;
    max-width: 12rem;
    align-self: center;
    margin: 0;
    aspect-ratio: ${width} / ${height};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-height: 4.5rem;
      max-width: 9rem;
    }
  `}
`;

interface TickerProps {
  itemCount?: number;
}

const tickerScroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

export const Ticker = styled.div<TickerProps>`
  ${({ theme, itemCount = 1 }) => css`
    display: flex;
    flex-direction: row;
    ${generateScales('column-gap', '2rem', '4rem', theme)}
    ${generateScales('height', '2rem', '4rem', theme)}
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    animation: ${tickerScroll} ${itemCount * 5}s linear infinite;

    &:hover {
      animation-play-state: paused;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
    }

    ${SecurtiyImage} {
      max-height: 3rem;
      max-width: unset;
      height: 100%;
    }
  `}
`;
