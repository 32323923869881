import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScalingQueries, generateScales } from '@helpers/generate-media-queries';

export const StyledLabel = styled.label<{
  $fontSize: keyof typeof defaultTheme.fontSize;
  $fontColor: keyof typeof defaultTheme.colors;
  $margin: keyof typeof defaultTheme.spacing;
}>`
  ${({ theme, $fontColor, $fontSize, $margin }) => {
    const bottomMargin: string = $margin === 'none' ? '0rem' : theme.spacing[$margin];

    return css`
      font-weight: 400;
      font-family: ${theme.fontFamily.text};
      font-size: ${theme.fontSize[$fontSize].desktop};
      line-height: ${theme.lineHeights.xs};
      color: ${theme.colors[$fontColor]};
      margin: 0 0 ${bottomMargin} 0;
      ${generateScales(
        'font-size',
        theme.fontSize[$fontSize].mobile,
        theme.fontSize[$fontSize].desktop,
        theme
      )}
      ${generateScalingQueries('margin-bottom', bottomMargin, theme)};
    `;
  }}
`;
