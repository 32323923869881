import styled, { css, keyframes, DefaultTheme } from 'styled-components';
import Text from '@components/text';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';
import { wrapperWidths } from '@components/wrapper.styled';
import { underlineTransition } from '@components/link/link.styled';
import { StyledMediumButton } from '@components/button/button.styled';

export const CookieText = styled(Text)`
  ${({ theme }) => css`
    display: block;
    color: ${theme.colors.darkPurple};
    font-size: 1rem;
    font-family: ${theme.fontFamily.additional};
    line-height: 1.375;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      font-size: 0.9375rem;
      line-height: 1.25;
    }

    a {
      line-height: 1.375;
      color: ${theme.colors.lightPurple};
      ${underlineTransition}

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        line-height: 1.25;
      }
    }
  `}
`;

const reveal = (offset: string) => keyframes`
  from {
    opacity: 0;
    transform: translateY(${offset});
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const consentContainer = (theme: DefaultTheme) => css`
  .consent-container {
    line-height: ${theme.lineHeights.lg};
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 2rem;
    background: ${theme.colors.brand};
    box-shadow: 0 0 4rem rgba(0, 0, 0, 0.25);
    font-family: ${theme.fontFamily.display};
    padding: 1.25rem 2rem;
    font-size: 1rem;
    border-radius: 1rem;
    font-weight: 400;
    animation: 0.375s ${reveal('2rem')} ease-out;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      justify-items: flex-start;
      row-gap: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      animation: 0.375s ${reveal('-1rem')} ease-in-out;
      padding: 1.25rem 1rem;
      margin-bottom: 0;
      box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.25);
      border-radius: 0.5rem;
      row-gap: 0.75rem;
    }
  }
`;

const consentBtnWrapper = (theme: DefaultTheme) => css`
  .consent-btn-wrapper {
    display: flex;
    column-gap: 0.75rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      column-gap: 0.5rem;
      width: 100%;
      padding: 0;
    }
  }
`;

export interface CookieConsentWrapperProps {
  bgColor?: keyof typeof defaultTheme.backgroundColors;
}

export const CookieConsentWrapper = styled.div<CookieConsentWrapperProps>`
  ${({ theme, bgColor }) => css`
    position: fixed;
    left: 50%;
    bottom: 1rem;
    z-index: 99;
    width: ${wrapperWidths.xxl};
    transform: translateX(-50%);
    padding: 0 0;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: ${wrapperWidths.xl};
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: ${wrapperWidths.lg};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: ${wrapperWidths.md};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      position: relative;
      bottom: unset !important;
      top: unset;
      width: 100%;
      background-color: ${theme.backgroundColors[bgColor || 'dark']};
      padding: 0.5rem;
    }

    ${consentContainer(theme)}

    ${consentBtnWrapper(theme)}
  `}
`;

const secondaryButton = (theme: DefaultTheme) => css`
  &:first-child {
    color: ${theme.colors.secondary};
    background: transparent;
    border-width: 1.5px;
    border-color: ${theme.colors.secondary};

    &:hover {
      color: ${theme.colors.white};
      border-color: ${theme.colors.primary};
      background: ${theme.colors.primary};
    }
  }
`;

const primaryButton = (theme: DefaultTheme) => css`
  background: ${theme.colors.secondary};
  border-color: ${theme.colors.secondary};
  color: ${theme.colors.white};

  &:hover {
    background: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
  }
`;

export const StyledButton = styled(StyledMediumButton)`
  ${({ theme }) => css`
    font-weight: 500;
    white-space: nowrap;
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      height: 2.25rem;
      padding: 0 1.125rem;
    }

    ${primaryButton(theme)}
    ${secondaryButton(theme)}
  `}
`;
