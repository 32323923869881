import styled, { css } from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  width: 100%;
`;

export const HelpText = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.additional};
    color: ${theme.colors.tertiary};
    font-size: ${theme.fontSize.extraSmall.desktop};
  `}
`;
