import React from 'react';
import { defaultTheme } from '@styles/theme.styled';
import { StyledSVG } from './svg-icon.styled';

interface ButtonProps {
  fill?: keyof typeof defaultTheme.colors;
  stroke?: string;
  containerStroke?: string;
  width?: number;
  height?: number;
  className?: string;
}

const Arrow = ({
  fill = 'darkPurple',
  stroke = 'white',
  containerStroke = 'dark',
  width = 24,
  height = 24,
  className = '',
}: ButtonProps): JSX.Element => (
  <StyledSVG
    color={fill}
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke={containerStroke}
      strokeWidth={defaultTheme.borderWidths.icons}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M12 16L16 12L12 8"
      stroke={stroke}
      strokeWidth={defaultTheme.borderWidths.icons}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M8 12H16"
      stroke={stroke}
      strokeWidth={defaultTheme.borderWidths.icons}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </StyledSVG>
);

export default Arrow;
