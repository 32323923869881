import React from 'react';
import { ImageProps } from '@components/image';
import generateId from '@helpers/generate-id';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultTheme } from '@styles/theme.styled';
import Text from '@components/text';
import { Padder, SecurityGrid, SecurtiyImage, Container, Ticker } from './security-labels.styled';

const SecurityLabelIdGenerator = generateId();

export interface SecurityLabelItemProps {
  image?: ImageProps;
}

interface SecurityLabelProps {
  items: SecurityLabelItemProps[];
  title?: string;
  description?: RichTextBlock[];
  align?: string;
  ticker?: boolean;
  backgroundAlignment?: string;
  displayBorder?: boolean;
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor?: keyof typeof defaultTheme.backgroundColors;
  itemsPerRow?: number;
}

const SecurityLabels = ({
  items,
  title,
  description,
  align,
  displayBorder,
  backgroundColor = 'white',
  backgroundAlignment = 'hidden',
  outerbackgroundColor = 'white',
  itemsPerRow = 3,
  ticker,
}: SecurityLabelProps): JSX.Element => {
  const isTextReceived = !!(title !== '' || description?.length);
  if (items?.length === 0 && isTextReceived) {
    return <></>;
  }

  return (
    <Section
      backgroundColor={backgroundColor}
      displayBorder={displayBorder}
      outerbackgroundColor={outerbackgroundColor}
      backgroundAlignment={backgroundAlignment}
    >
      <Wrapper>
        <Padder backgroundAlignment={backgroundAlignment}>
          {isTextReceived && (
            <Container backgroundColor={backgroundColor} align={align}>
              {!!title && (
                <Text
                  tag="h2"
                  type="title"
                  fontSize="heading2"
                  stringText={title}
                  margin="none"
                  fontColor={
                    backgroundColor === 'dark' || backgroundColor === 'primary'
                      ? 'white'
                      : 'primary'
                  }
                />
              )}
              {!!description?.length && (
                <Text
                  type="subheading"
                  richText={description}
                  fontColor={
                    backgroundColor === 'dark' || backgroundColor === 'primary'
                      ? 'white'
                      : 'primary'
                  }
                />
              )}
            </Container>
          )}
          {ticker ? (
            <Ticker itemCount={items.length}>
              {items.map(({ image }): JSX.Element => {
                return (
                  <SecurtiyImage
                    key={SecurityLabelIdGenerator.next().value}
                    url={image?.url}
                    alt={image?.alt}
                    gatsbyImageData={image?.gatsbyImageData}
                    height={image?.gatsbyImageData?.height}
                    width={image?.gatsbyImageData?.width}
                  />
                );
              })}
              {items.map(({ image }): JSX.Element => {
                return (
                  <SecurtiyImage
                    key={SecurityLabelIdGenerator.next().value}
                    url={image?.url}
                    alt={image?.alt}
                    gatsbyImageData={image?.gatsbyImageData}
                    height={image?.gatsbyImageData?.height}
                    width={image?.gatsbyImageData?.width}
                  />
                );
              })}
            </Ticker>
          ) : (
            <SecurityGrid itemsPerRow={itemsPerRow}>
              {items.map(({ image }): JSX.Element => {
                return (
                  <SecurtiyImage
                    key={SecurityLabelIdGenerator.next().value}
                    url={image?.url}
                    alt={image?.alt}
                    gatsbyImageData={image?.gatsbyImageData}
                    objectFit="contain"
                  />
                );
              })}
            </SecurityGrid>
          )}
        </Padder>
      </Wrapper>
    </Section>
  );
};

export default SecurityLabels;
