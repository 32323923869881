import React from 'react';
import Image, { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultTheme } from '@styles/theme.styled';
import Text from '@components/text';
import {
  ImageContainer,
  Container,
  MessageContainer,
  BackgroundImageContainer,
  StyledSection,
} from './text-and-image.styled';

interface TextImageProps {
  title?: string;
  subheading?: RichTextBlock[];
  image?: ImageProps;
  align?: string;
  backgroundAlignment?: string;
  displayBorder?: boolean;
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor?: keyof typeof defaultTheme.backgroundColors;
  backgroundImage?: ImageProps;
}

const TextImage = ({
  title,
  subheading = [],
  image,
  align,
  displayBorder,
  backgroundColor = 'white',
  backgroundAlignment = 'hidden',
  outerbackgroundColor = 'white',
  backgroundImage,
}: TextImageProps): JSX.Element => {
  const isSubheadingExisting: boolean = subheading?.length > 0 && subheading[0].text !== '';
  if (!title && !image && !isSubheadingExisting && !backgroundImage) {
    return <></>;
  }

  return (
    <StyledSection
      backgroundColor={backgroundColor}
      displayBorder={displayBorder}
      outerbackgroundColor={outerbackgroundColor}
      backgroundAlignment={backgroundAlignment}
    >
      <Wrapper>
        <Container>
          <MessageContainer align={align}>
            {title && (
              <Text
                type="title"
                tag="h2"
                stringText={title}
                fontColor="primary"
                fontSize="heading3"
                margin="md"
              />
            )}
            {isSubheadingExisting && (
              <Text
                type="subheading"
                fontSize="subheading"
                fontColor="dark"
                richText={subheading}
              />
            )}
          </MessageContainer>
          <ImageContainer>
            {(image?.gatsbyImageData || image?.url) && (
              <Image
                url={image.url}
                alt={image.alt || ''}
                gatsbyImageData={image.gatsbyImageData}
                objectFit="contain"
              />
            )}
          </ImageContainer>
          <BackgroundImageContainer>
            {(backgroundImage?.gatsbyImageData || backgroundImage?.url) && (
              <Image
                url={backgroundImage.url}
                alt={backgroundImage.alt || ''}
                gatsbyImageData={backgroundImage.gatsbyImageData}
              />
            )}
          </BackgroundImageContainer>
        </Container>
      </Wrapper>
    </StyledSection>
  );
};

export default TextImage;
