import React from 'react';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledTitle } from './title.styled';

const Title = ({
  margin = 'md',
  role,
  fontColor = 'primary',
  fontSize = 'heading4',
  tag = 'h2',
  stringText = '',
  className,
  id,
}: TextComponentProps): JSX.Element => {
  return (
    <StyledTitle
      $margin={margin}
      $fontSize={fontSize}
      $fontColor={fontColor}
      className={className}
      as={tag}
      id={id}
      role={role}
    >
      {stringText}
    </StyledTitle>
  );
};

export default Title;
