import styled, { css } from 'styled-components';
import Image from '@components/image';
import Text from '@components/text';
import LinkComponent from '@components/link';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';
import { underlineTransition } from '@components/link/link.styled';
import { MasterDispatchProps } from './index';

export const Container = styled.div<MasterDispatchProps>`
  ${({ itemsLayoutType }) =>
    itemsLayoutType === 'on side' &&
    css`
      display: flex;
      flex-wrap: wrap;
    `}

  ${({ itemsLayoutType, theme }) =>
    itemsLayoutType === 'under' &&
    css`
      display: flex;
      flex-direction: column;
      row-gap: 4rem;
      ${generateScales('row-gap', '2rem', '4rem', theme)}
      align-items: center;

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        align-items: flex-start;
      }
    `}

  ${({ layoutType }) =>
    layoutType === 'column' &&
    css`
      width: 100%;
      margin: 0 auto;
    `}

  ${({ theme, isBorderBottom }) =>
    isBorderBottom &&
    css`
      padding: 0 0 ${theme.padding.xl};
      border-bottom: 1px solid #f2c2ff;
    `}
`;

export const OnSideContainer = styled.div<MasterDispatchProps>`
  ${({ theme, layoutType }) => css`
    display: flex;
    flex-direction: column;
    max-width: 38rem;
    width: 100%;

    ${layoutType === 'column' &&
    css`
      align-items: center;
      max-width: unset;
      ${generateScales('row-gap', '1rem', '2rem', theme)}
    `}

    ${layoutType === 'row' &&
    css`
      ${generateScales('row-gap', '1.25rem', '2.5rem', theme)}

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        max-width: unset;
        align-items: center;
      }
    `}

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      flex-direction: column;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      align-items: flex-start;
    }
  `}
`;

export const HeadContainer = styled.div<MasterDispatchProps>`
  ${({ theme, align, layoutType, itemsLayoutType }) => css`
    display: flex;
    width: 100%;
    row-gap: 3rem;
    align-items: center;

    ${generateScales('row-gap', '2.5rem', '3rem', theme)}

    ${itemsLayoutType === 'under' && css``}

    ${layoutType === 'row' &&
    css`
      flex-direction: ${align === 'right' ? 'row-reverse' : 'row'};
      ${generateScales('column-gap', '2rem', '4rem', theme)}

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        flex-direction: column;
      }
    `}
  `}
`;

interface StyledImageProps {
  itemsLayoutType?: string;
  layoutType?: string;
  align?: string;
}

export const StyledImage = styled(Image)<StyledImageProps>`
  ${({ theme, align, itemsLayoutType, layoutType }) => css`
    width: 100%;
    max-height: 36rem;

    ${itemsLayoutType === 'under'
      ? css`
          max-height: 16rem;

          @media only screen and (max-width: ${theme.breakpoints.sm}) {
            max-height: 14rem;
            img {
              object-position: center center;
            }
          }
        `
      : css`
          /* transform: ${align === 'right' ? 'translateX(4rem)' : 'translateX(-4rem)'}; */
        `}

    ${layoutType === 'column' &&
    css`
      max-height: 20rem;
      max-width: 56rem;
      transform: unset;

      ${align === 'right' &&
      css`
        order: 2;
        max-height: 16rem;
      `}

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        max-height: 12rem;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        img {
          object-position: center center;
        }
      }
    `}

    ${layoutType === 'row' &&
    css`
      img {
        object-position: center center;
      }
      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        transform: unset;
        max-height: 16rem;
      }
      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        img {
          object-position: center center;
        }
      }
    `}
  `}
`;

export const TextContent = styled.div<MasterDispatchProps>`
  ${({ theme, is_thumbnail }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales(
      'row-gap',
      is_thumbnail ? '0.75rem' : '0.5rem',
      is_thumbnail ? '1.25rem' : '0.5rem',
      theme
    )}
  `}
`;

export const StyledDescription = styled(Text)``;

export const StyledHeading = styled(Text)<MasterDispatchProps>`
  ${({ theme, fontColor }) => css`
    color: ${fontColor === 'white' ? theme.colors.white : theme.colors.primary};
  `}
`;

export const StyledEyebrow = styled(Text)``;

export const StyledContent = styled.div<MasterDispatchProps>`
  ${({ layoutType, theme }) => css`
    width: 100%;

    ${layoutType === 'row' &&
    css`
      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        text-align: center;
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        max-width: unset;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        text-align: left;
      }
    `};

    ${layoutType === 'column' &&
    css`
      display: flex;
      flex-direction: column;
      ${generateScales('row-gap', '2rem', '4rem', theme)}
      text-align: center;
      align-items: center;

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        text-align: left;
        align-items: flex-start;
      }

      ${StyledDescription} {
        max-width: 54rem;
        margin: 0 auto;
        display: block;
      }

      ${StyledHeading} {
        max-width: 50rem;
        margin: 0 auto;

        @media only screen and (max-width: ${theme.breakpoints.sm}) {
          margin: 0;
        }
      }
    `}
  `}
`;

export const StyledTextContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales('row-gap', '0.75rem', '1.25rem', theme)}
  `}
`;

export const StyledGrid = styled.div<MasterDispatchProps>`
  ${({ theme, itemsLayoutType, layoutType, itemsPerRow = 2 }) => css`
    display: grid;
    text-align: left;
    row-gap: 2.5rem;
    column-gap: 2.5rem;
    ${generateScales('row-gap', '1.5rem', '3rem', theme)}
    ${generateScales('column-gap', '2rem', '4rem', theme)}

    ${itemsPerRow < 3 &&
    css`
      max-width: 56rem;
    `}

    ${itemsPerRow === 1 &&
    css`
      width: 100%;
    `}

    ${layoutType === 'row' &&
    css`
      grid-template-columns: repeat(${itemsPerRow}, 1fr);
      ${generateScales('column-gap', '1.25rem', '2.5rem', theme)}
      ${generateScales('row-gap', '1.25rem', '2.5rem', theme)}
    `};

    ${layoutType === 'column' &&
    css`
      grid-template-columns: repeat(${itemsPerRow}, 1fr);

      ${itemsPerRow > 1 &&
      css`
        @media only screen and (max-width: ${theme.breakpoints.md}) {
          grid-template-columns: repeat(2, 1fr);
        }
      `}
    `};

    ${itemsLayoutType === 'under' &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: repeat(1, 1fr);
    }
  `}
`;

export const StyledBlock = styled.div<MasterDispatchProps>`
  ${({ theme, is_thumbnail, layoutType, itemsPerRow }) => css`
    display: flex;
    width: 100%;
    min-width: 16rem;
    flex-direction: ${layoutType === 'row' ? 'column' : 'row'};
    column-gap: 1rem;
    row-gap: 1rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      flex-direction: column;
      row-gap: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      min-width: unset;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: ${is_thumbnail ? 'column' : 'row'};
      column-gap: 1rem;
      row-gap: 0.5rem;
    }

    ${itemsPerRow === 1 &&
    css`
      flex-direction: row;

      @media only screen and (max-width: ${theme.breakpoints.xl}) {
        flex-direction: row;
      }
    `}

    ${is_thumbnail &&
    css`
      flex-direction: column;
      row-gap: 1rem;

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        flex-direction: column;
        row-gap: 1rem;
      }
    `}
  `}
`;

export const StyledIcon = styled.div`
  ${({ theme }) => css`
    min-width: 3.25rem;
    min-height: 3.25rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      min-width: 3rem;
      min-height: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      min-width: 2.5rem;
      min-height: 2.5rem;
    }
  `}
`;

interface LinkComponentProps {
  fontColor: keyof typeof defaultTheme.colors;
}

export const StyledLinkComponent = styled(LinkComponent)<LinkComponentProps>`
  ${({ theme, fontColor }) => {
    const { mobile, desktop } = theme.fontSize.subheading;
    return css`
      ${generateScales('font-size', mobile, desktop, theme)}
      font-weight: 500;
      width: fit-content;
      margin-top: 0;

      a {
        color: ${fontColor === 'white' ? theme.colors.white : theme.colors.secondary};
      }
    `;
  }}
`;

export const StyledJumpLink = styled.a`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.display};
    font-size: 1rem;
    ${underlineTransition}
    color: ${theme.colors.secondary};
    text-align: center;
    display: block;
    width: fit-content;
    line-height: 1.25;
    margin: 4rem auto 0;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      margin: 4rem 0 0;
      text-align: left;
    }
  `}
`;
