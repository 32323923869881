import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import StyledInput from '../input.styled';

const LargeInput = styled(StyledInput)`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return css`
      border-radius: 0.125rem;
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      ${generateScales('padding-left', '0.5rem', '0.75rem', theme)}
      ${generateScales('padding-right', '0.5rem', '0.75rem', theme)}
      ${generateScales('height', '3rem', '3.25rem', theme)}
    `;
  }}
`;

export default LargeInput;
