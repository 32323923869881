import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales, generateScalingQueries } from '@helpers/generate-media-queries';
import { underlineTransition } from '@components/link/link.styled';

export const StyledSubheading = styled.div<{
  $fontSize: keyof typeof defaultTheme.fontSize;
  $fontColor: keyof typeof defaultTheme.colors;
  $margin: keyof typeof defaultTheme.spacing | undefined;
}>`
  ${({ theme, $margin, $fontSize, $fontColor }) => {
    const bottomMargin: string = $margin ? theme.spacing[$margin] : '0rem';

    return css`
      margin: 0 0 ${bottomMargin} 0;
      font-family: ${theme.fontFamily.text};
      font-weight: ${theme.fontWeights.normal};
      line-height: ${theme.lineHeights.lg};
      color: ${theme.colors[$fontColor]};
      ${generateScales(
        'font-size',
        theme.fontSize[$fontSize].mobile,
        theme.fontSize[$fontSize].desktop,
        theme
      )}
      margin-bottom: ${bottomMargin};
      ${generateScalingQueries('margin-bottom', bottomMargin, theme)}

      p + p {
        ${generateScales(
          'margin-top',
          theme.fontSize[$fontSize].mobile,
          theme.fontSize[$fontSize].desktop,
          theme
        )}
      }

      a {
        color: ${theme.colors.secondary};
        ${underlineTransition}
        background-position: 100% 100%;
        background-size: 100% 0.15rem;

        :hover,
        :focus {
          background-size: 0% 0.15rem;
        }
      }
    `;
  }}
`;
