import styled, { css } from 'styled-components';
import LinkComponent from '@components/link';
import { generateScales } from '@helpers/generate-media-queries';

export const NotificationContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  margin: 0;
`;

export const Notification = styled(LinkComponent)`
  ${({ theme }) => css`
    white-space: normal;
    background: transparent;
    ${generateScales('border-radius', '1.25rem', '1.75rem', theme)}
    padding: 0.5rem 1.25rem;
    border: 1px solid ${theme.colors.white};
    ${generateScales('height', '2.5rem', '3.5rem', theme)}
    font-family: ${theme.fontFamily.text};
    font-weight: ${theme.fontWeights.bold};
    font-size: 1rem;
    color: ${theme.colors.white};

    ::before {
      content: '';
      position: absolute;
      inset: 0;
      ${generateScales('border-radius', '1.25rem', '1.75rem', theme)}
      padding: 1.25px;
      background: linear-gradient(90deg, #d741a7, #aa1ef5, #35f08d);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &:hover {
      background-color: transparent;
      ::before {
        background: ${theme.colors.white};
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      height: unset;
      padding: 0.75rem 1.25rem;
      border-radius: 100rem;
      display: grid;
      grid-template-columns: 1.5rem 10fr 1.125rem;

      &::before {
        border-radius: 100rem;
      }

      span {
        line-height: 1.125;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      span {
        text-align: left;
      }
    }
  `}
`;
