import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import StyledInput from '../input.styled';

const SmallInput = styled(StyledInput)`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.sm;
    return css`
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('height', '2.5rem', '2.75rem', theme)}
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
    `;
  }}
`;

export default SmallInput;
