import React from 'react';
import Text from '@components/text';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import SquareArrow from '@components/svg-icons/square-arrow';
import generateId from '@helpers/generate-id';
import {
  TitleContainer,
  StyledTitle,
  StyledTitleLink,
  StyledLink,
  StyledItem,
  Container,
  StyledText,
  TextAndIcon,
} from './positions.styled';

const positionsIdGenerator = generateId();

export interface PositionsItemType {
  position?: {
    document: {
      data: {
        title: {
          text: string;
        };
      };
      uid: string;
    };
  };
}

interface PositionsProps {
  title?: string;
  subtitle?: string;
  linkLabel?: string;
  items?: PositionsItemType[];
}

const Positions = ({ title, subtitle, linkLabel = 'Apply', items = [] }: PositionsProps) => {
  return items.length ? (
    <Section backgroundColor="transparent">
      <Wrapper>
        <TitleContainer>
          {!!title && (
            <StyledTitle
              type="title"
              tag="h2"
              stringText={title}
              fontColor="secondary"
              fontSize="heading2"
            />
          )}
          {!!subtitle && <Text type="title" tag="p" stringText={subtitle} fontSize="heading3" />}
        </TitleContainer>
        <Container>
          {items?.map(
            ({ position }) =>
              !!position?.document.uid && (
                <StyledItem key={positionsIdGenerator.next().value}>
                  <StyledTitleLink to={`/careers/${position.document.uid}`}>
                    {position?.document.data.title.text}
                  </StyledTitleLink>
                  <StyledLink to={`/careers/${position.document.uid}`}>
                    <TextAndIcon>
                      <StyledText type="title" fontSize="large" stringText={linkLabel} />
                      <SquareArrow />
                    </TextAndIcon>
                  </StyledLink>
                </StyledItem>
              )
          )}
        </Container>
      </Wrapper>
    </Section>
  ) : null;
};

export default Positions;
