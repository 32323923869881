import styled, { css, DefaultTheme } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';
import BackgroundMask from '@images/background-mask.svg';
import { wrapperWidths } from '@components/wrapper.styled';

const MaskLeftPositioning = css`
  ${({ theme }) => css`
    mask-position: bottom 0% left calc(50% - (${wrapperWidths.xxl} * (0.5 / 2)));

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      mask-position: bottom 0% left calc(50% - (${wrapperWidths.xl} * (0.5 / 2)));
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      mask-position: bottom 0% left calc(50% - (${wrapperWidths.lg} * (0.5 / 2)));
      mask-size: 1720px 1440px;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      mask-position: bottom center;
      mask-size: 1720px 1440px;
    }
  `}
`;

const MaskRighttPositioning = css`
  ${({ theme }) => css`
    mask-position: bottom 0% right calc(50% - (${wrapperWidths.xxl} * (0.5 / 2)));

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      mask-position: bottom 0% right calc(50% - (${wrapperWidths.xl} * (0.5 / 2)));
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      mask-position: bottom center;
    }
  `}
`;

const MaskCenteredPositioning = css`
  mask-position: bottom center;
`;

const twinStyling = (backgroundAlignment: string | undefined) => {
  const theme = defaultTheme;
  const styles = Object.keys(theme.backgroundColors)
    .map(
      (color) => `
    &.section-${color}:not(.gradient-border) + .section-${color} {
      ${generateScales(
        'padding-top',
        backgroundAlignment !== 'hidden' ? '2rem' : '1rem',
        backgroundAlignment !== 'hidden' ? '4.5rem' : '2rem',
        theme
      )}
    }
  `
    )
    .join('');

  return css`
    ${styles}
  `;
};

interface SectionProps {
  backgroundColor: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor: keyof typeof defaultTheme.backgroundColors;
  displayBorder?: boolean;
  backgroundAlignment?: string;
  theme: DefaultTheme;
}

const MaskedShape = (
  backgroundColor: keyof typeof defaultTheme.backgroundColors,
  theme: DefaultTheme,
  backgroundAlignment?: string,
  displayBorder?: boolean
) => css`
  ::after {
    content: '';
    z-index: 2;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    ${displayBorder
      ? css`
          /* ${generateScales('bottom', '-1.75rem', '-2rem', theme)} */
          bottom: 0.5rem;
        `
      : css`
          bottom: 0;
          /* ${generateScales('bottom', '-1.25rem', '-1.5rem', theme)} */
        `}
    background: ${backgroundColor === 'gradient'
      ? `linear-gradient(240deg, #AA1EF5 25%, #3D1152 75%);`
      : theme.backgroundColors[backgroundColor]};
    mask-image: url('${BackgroundMask}');
    mask-repeat: no-repeat;
    mask-size: 3440px 2880px;
    ${backgroundAlignment === 'center' && MaskCenteredPositioning}
    ${backgroundAlignment === 'left' && MaskLeftPositioning}
        ${backgroundAlignment === 'right' && MaskRighttPositioning}
  }
`;

export const StyledSection = styled.section<SectionProps>`
  ${({
    theme,
    backgroundColor,
    outerbackgroundColor,
    backgroundAlignment,
    displayBorder = true,
  }) => css`
    width: 100%;
    position: relative;
    ${backgroundColor === 'gradient'
      ? css`
          background: ${backgroundAlignment !== 'hidden'
            ? theme.backgroundColors[outerbackgroundColor]
            : theme.backgroundColors[backgroundColor]};
          a {
            color: ${theme.colors.light} !important;
          }
        `
      : css`
          background: ${backgroundAlignment !== 'hidden'
            ? theme.backgroundColors[outerbackgroundColor]
            : theme.backgroundColors[backgroundColor]};
        `}

    ${twinStyling(backgroundAlignment)}

${!!(
      backgroundAlignment === 'left' ||
      backgroundAlignment === 'center' ||
      backgroundAlignment === 'right'
    ) &&
    !!(
      backgroundColor === 'gradient' ||
      backgroundColor === 'dark' ||
      backgroundColor === 'primary'
    )
      ? css`
          ${generateScales('padding-top', '3rem', '6rem', theme)}
          ${generateScales('padding-bottom', '4.5rem', '9rem', theme)}
        `
      : css`
          ${generateScales('padding-top', '3rem', '6rem', theme)}
          ${generateScales('padding-bottom', '3rem', '6rem', theme)}
        `}

    // add shape
    ${backgroundAlignment !== 'hidden' &&
    css`
      ${generateScales('padding-bottom', '4.5rem', '9rem', theme)}

      ${MaskedShape(backgroundColor, theme, backgroundAlignment, displayBorder)}

      // adds a faux gradient border if eneabled
    ${displayBorder === true &&
      css`
        // uses same mask as content to create the illusion of a border
        ::before {
          content: '';
          z-index: 1;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: ${backgroundColor === 'dark' ||
          backgroundColor === 'primary' ||
          backgroundColor === 'gradient'
            ? `linear-gradient(283.5deg, #FFB3B4 29.94%, #D741A7 55.02%, #AA1EF5 85.29%);`
            : `linear-gradient(270deg, #b3ffcf 25%, #a3e7ff 50%, #aa1ef5 75%)`};
          mask-image: url('${BackgroundMask}');
          mask-repeat: no-repeat;
          mask-size: 3440px 2880px;
          ${backgroundAlignment === 'center' && MaskCenteredPositioning}
          ${backgroundAlignment === 'left' && MaskLeftPositioning}
        ${backgroundAlignment === 'right' && MaskRighttPositioning}
        }

        ${MaskCenteredPositioning}
      `}
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    z-index: ${theme.zIndex.layeredContent};
    position: relative;
  `}
`;
