import styled, { css } from 'styled-components';
import Image from '@components/image';
import LinkComponent from '@components/link';
import { wrapperWidths } from '@components/wrapper.styled';
import { generateScales } from '@helpers/generate-media-queries';
import BackgroundMask from '@images/background-mask.svg';
import { headerHeight } from '@components/header/header.styled';
import { PageHeroProps } from './index';

export interface PageHeroStyleProps extends PageHeroProps {
  $isLeftAligned: boolean;
}

const MaskShiftedPositioning = css`
  ${({ theme }) => css`
    mask-position: bottom 0% left calc(50% - (${wrapperWidths.xxl} * (0.5 / 2)));

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      mask-position: bottom 0% left calc(50% - (${wrapperWidths.xl} * (0.5 / 2)));
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      mask-position: bottom center;
      mask-size: 1720px 1440px;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      mask-position: bottom center;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      mask-position: bottom center;
    }
  `}
`;

export const EyebrowWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.5rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      justify-content: center;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      column-gap: 0.375rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      justify-content: flex-start;
    }
  `}
`;

export const Badge = styled.div`
  ${({ theme }) => css`
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    border-radius: 4rem;
    background-color: ${theme.colors.accent};
    font-family: ${theme.fontFamily.additional};
    color: ${theme.colors.black};
    padding: 0 0.625rem;
    ${generateScales('height', '1.375rem', '1.5rem', theme)}
    font-weight: 600;
    ${generateScales(
      'font-size',
      theme.fontSize.extraSmall.mobile,
      theme.fontSize.extraSmall.desktop,
      theme
    )}
  `}
`;

export const StyledImage = styled(Image)`
  ${({ theme }) => css`
    z-index: 1;
    width: 100%;
    max-width: 32rem;
    max-height: 26rem;
    height: auto;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: 20rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 20rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: 16rem;

      img {
        object-position: left center;
      }
    }
  `}
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const Content = styled.div<PageHeroStyleProps>`
  ${({ theme, secondaryNav, $isLeftAligned, image, backgroundImage }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    z-index: 1;
    width: 100%;

    ${image?.url
      ? css`
          @media only screen and (max-width: ${theme.breakpoints.lg}) {
            align-items: center;
          }

          @media only screen and (max-width: ${theme.breakpoints.sm}) {
            align-items: flex-start;
            row-gap: 2rem;
          }
        `
      : css`
          max-width: 52rem;
          align-items: center;

          @media only screen and (max-width: ${theme.breakpoints.md}) {
            align-items: ${backgroundImage ? 'center' : 'flex-start'};
          }
        `}

    ${$isLeftAligned &&
    css`
      max-width: 42rem !important;
      align-items: flex-start !important;
      text-align: left !important;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        max-width: 52rem !important;
        align-items: center !important;
        text-align: center !important;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        text-align: left !important;
      }
    `}

    // Add padding if an anchor navigation is available
    ${!!secondaryNav?.length &&
    !!secondaryNav[0].anchor_text &&
    css`
      margin-bottom: 3rem;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        margin-bottom: 8rem;
      }
    `}
  `}
`;

export const Header = styled.header<PageHeroStyleProps>`
  ${({
    theme,
    outerbackgroundColor,
    backgroundColor,
    image,
    isShifted,
    $isLeftAligned,
    backgroundImage,
  }) => {
    const HeroGradientBorder = css`
      content: '';
      z-index: 0;
      display: content;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(270deg, #b3ffcf 25%, #a3e7ff 50%, #aa1ef5 75%);
      mask-image: url('${BackgroundMask}');
      mask-repeat: no-repeat;
      mask-size: 3440px 2880px;
    `;

    const MaskCenteredPositioning = css`
      mask-position: bottom center;
    `;

    const GradientBorder = css`
      ${isShifted &&
      css`
        ::after {
          ${!!image?.url === true ? MaskShiftedPositioning : MaskCenteredPositioning};
          ${HeroGradientBorder}
          background: white;
          bottom: 0.5rem;
        }

        ::before {
          ${HeroGradientBorder}
          ${!!image?.url === true ? MaskShiftedPositioning : MaskCenteredPositioning};
        }
      `}
    `;

    const SequentialSection = Object.keys(theme.backgroundColors)
      .map(
        (color) => `
          &.hero-${color} + .section-${color} {
            ${generateScales('padding-top', '1rem', '2rem', theme)}
          }
      `
      )
      .join('');

    const BackgroundElement = css`
      &::before {
        content: '';
        z-index: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        ${backgroundColor &&
        css`
          background-color: ${theme.backgroundColors[backgroundColor]};
        `};

        ${backgroundImage &&
        css`
          background-image: url(${backgroundImage?.url});
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        `};

        mask-image: url('${BackgroundMask}');
        mask-repeat: no-repeat;
        mask-size: 3440px 2880px;
        mask-position: bottom center;

        @media only screen and (max-width: ${theme.breakpoints.md}) {
          mask-size: 1720px 1440px;
        }

        @media only screen and (max-width: ${theme.breakpoints.sm}) {
          mask-position: bottom center;
        }
      }
    `;

    return css`
      display: flex;
      position: relative;
      width: 100%;
      margin: 0;
      background-color: ${outerbackgroundColor
        ? theme.backgroundColors[outerbackgroundColor]
        : 'transparent'};

      ${backgroundColor !== outerbackgroundColor &&
      generateScales('padding-bottom', '3rem', '8rem', theme)}
      padding-top: ${headerHeight};

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        padding-top: 4rem;
      }

      ${SequentialSection}
      ${BackgroundElement}
      ${GradientBorder}


      ${$isLeftAligned &&
      css`
        @media only screen and (max-width: ${theme.breakpoints.lg}) {
          ${backgroundImage &&
          css`
            &::before {
              background-image: none !important;
            }
          `}
        }

        + section {
          ${generateScales('margin-top', '-10rem', '-18rem', theme)}
          background-color: transparent !important;
          h2 {
            color: ${theme.colors.white} !important;
          }
        }
      `}
    `;
  }}
`;

export const StyledWrapper = styled.div<PageHeroStyleProps>`
  ${({ theme, $isLeftAligned, image, backgroundImage }) => css`
    display: flex;
    ${generateScales('padding-top', '2rem', '3rem', theme)}
    ${generateScales('padding-bottom', '2rem', '3rem', theme)}
    /* padding-top: 2rem;
    padding-bottom: 2rem; */

    ${!image?.url
      ? css`
          flex-direction: row;
          column-gap: 4rem;

          @media only screen and (max-width: ${theme.breakpoints.lg}) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            row-gap: 2rem;

            ${StyledImage} {
              max-height: 25vh;
              width: auto;
            }
          }

          @media only screen and (max-width: ${theme.breakpoints.sm}) {
            text-align: left;
            align-items: flex-start;
          }
        `
      : css`
          flex-direction: column;
          align-items: center;
          text-align: center;

          @media only screen and (max-width: ${theme.breakpoints.md}) {
            text-align: left;
            align-items: flex-start;

            ${backgroundImage &&
            css`
              align-items: center;
              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              p {
                text-align: center;
              }
            `};
          }
        `}

    align-items: center;
    text-align: left;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      text-align: center !important;
      align-items: center !important;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left !important;
      align-items: flex-start !important;
    }

    ${$isLeftAligned &&
    css`
      align-items: flex-start !important;
      text-align: left !important;

      ${generateScales('margin-bottom', '4rem', '8rem', theme)}
    `}
  `}
`;

export const LinkButton = styled(LinkComponent)<PageHeroProps>`
  ${({ theme, backgroundImage }) => {
    const hasBGImage =
      backgroundImage?.url !== undefined &&
      backgroundImage.url !== '' &&
      backgroundImage.url !== null;

    return css`
      ${hasBGImage &&
      css`
        box-shadow: ${theme.shadows.normal};
      `};

      &:hover {
        background-color: ${theme.colors.secondary};
        color: ${theme.colors.white};
      }
    `;
  }}
`;
