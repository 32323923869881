import styled, { css } from 'styled-components';

interface InputProps {
  $isInvalid?: boolean;
}

const StyledInput = styled.input<InputProps>`
  ${({ theme, $isInvalid }) => css`
    border: 1px solid ${$isInvalid ? theme.colors.tertiary : theme.colors.secondary};
    background-color: ${theme.colors.white};
    color: ${theme.colors.darkPurple};
    outline: none;
    font-family: ${theme.fontFamily.text};
    transition: all ${theme.transitions.normal};
    border-radius: 0.125rem;

    ::placeholder {
      color: ${theme.colors.dark}99;
    }

    :focus {
      border-color: ${theme.colors.dark};
      outline: 4px solid ${$isInvalid ? theme.colors.tertiary : theme.colors.secondary}22;
    }
  `}
`;

export default StyledInput;
