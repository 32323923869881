import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Image, { ImageProps } from '@components/image';
import { defaultTheme } from '@styles/theme.styled';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import generateId from '@helpers/generate-id';
import getLinkConfig from '@helpers/get-link-config';
import Text from '@components/text';
import {
  HeadContainer,
  StyledHeading,
  TextContent,
  StyledImage,
  StyledDescription,
  StyledEyebrow,
  StyledGrid,
  StyledBlock,
  StyledIcon,
  StyledLinkComponent,
  StyledContent,
  StyledTextContent,
  Container,
  OnSideContainer,
  StyledJumpLink,
} from './master-dispatch.styled';

const idGenerator = generateId();

interface MasterDispatchItemType {
  icon?: ImageProps;
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
}

export interface MasterDispatchProps {
  anchorLink?: string;
  topAnchorLink?: boolean;
  image?: ImageProps;
  eyebrow?: string;
  title?: string;
  description?: RichTextBlock[];
  align?: string;
  layoutType?: string;
  itemsLayoutType?: string;
  backgroundAlignment?: string;
  displayBorder?: boolean;
  is_thumbnail?: boolean;
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor?: keyof typeof defaultTheme.backgroundColors;
  items?: MasterDispatchItemType[];
  link?: {
    uid?: string;
    url?: string;
  };
  linkLabel?: string;
  isBorderBottom?: boolean;
  fontSize?: keyof typeof defaultTheme.fontSize;
  fontColor?: 'dark' | 'white';
  itemsPerRow?: number;
}

const MasterDispatch = ({
  topAnchorLink,
  anchorLink,
  image,
  eyebrow,
  title,
  description,
  align = 'left',
  layoutType = 'column',
  itemsLayoutType = 'under',
  displayBorder,
  backgroundColor = 'white',
  backgroundAlignment = 'hidden',
  outerbackgroundColor = 'white',
  items = [],
  link,
  is_thumbnail = false,
  linkLabel = 'Connect with us demo',
  isBorderBottom = false,
  fontSize = 'heading3',
  fontColor = 'dark',
  itemsPerRow = 2,
}: MasterDispatchProps): JSX.Element => {
  const linkConfig = getLinkConfig(link);
  const richTextLength = items[0].description?.richText.length || 0;
  const hasItems = items[0].icon?.url !== null || richTextLength > 0 || items[0].title?.text !== '';
  const headingSize = fontSize || 'heading3';

  const renderImage = (): JSX.Element => (
    <>
      {!!(image?.gatsbyImageData || image?.url) && (
        <StyledImage
          gatsbyImageData={image?.gatsbyImageData}
          url={image?.url}
          alt={image?.alt || ''}
          objectFit="contain"
          itemsLayoutType={itemsLayoutType}
          layoutType={layoutType}
          align={align}
        />
      )}
    </>
  );

  const renderHeadText = (): JSX.Element => (
    <StyledContent itemsLayoutType={itemsLayoutType} layoutType={layoutType} align={align}>
      {layoutType === 'column' && renderImage()}
      <StyledTextContent>
        {!!eyebrow && <StyledEyebrow type="eyebrow" stringText={eyebrow} fontSize="eyebrow" />}
        {!!title && (
          <StyledHeading
            type="title"
            tag="h2"
            stringText={title}
            fontSize={headingSize}
            fontColor={fontColor}
            margin="none"
          />
        )}
        {!!description && description.length > 0 && description[0]?.text !== '' && (
          <StyledDescription
            type="subheading"
            richText={description}
            fontSize="subheading"
            fontColor={fontColor}
            margin="none"
          />
        )}
      </StyledTextContent>
    </StyledContent>
  );

  const renderGrid = (): JSX.Element => (
    <StyledGrid
      itemsPerRow={items?.length === 1 ? 1 : itemsPerRow}
      itemsLayoutType={itemsLayoutType}
      layoutType={layoutType}
    >
      {items?.map((item) => (
        <StyledBlock
          layoutType={layoutType}
          itemsLayoutType={itemsLayoutType}
          itemsPerRow={itemsPerRow}
          key={idGenerator.next().value}
          is_thumbnail={is_thumbnail}
        >
          <StyledIcon>
            {!!(item.icon?.gatsbyImageData || item.icon?.url) && (
              <Image
                gatsbyImageData={item.icon?.gatsbyImageData}
                url={item.icon?.url}
                alt={item.icon?.alt || ''}
              />
            )}
          </StyledIcon>

          <TextContent is_thumbnail={is_thumbnail}>
            {!!item.title?.text && (
              <Text
                type="title"
                tag="h3"
                stringText={item.title.text}
                fontSize="heading6"
                fontColor={fontColor === 'dark' ? 'darkPurple' : 'white'}
                margin="none"
              />
            )}

            {!!item.description?.richText.length && (
              <Text
                type="description"
                richText={item.description.richText}
                fontSize={itemsPerRow < 4 ? 'body' : 'small'}
                fontColor={fontColor}
                margin="none"
              />
            )}
          </TextContent>
        </StyledBlock>
      ))}
    </StyledGrid>
  );

  return (
    <Section
      backgroundColor={backgroundColor}
      displayBorder={displayBorder}
      outerbackgroundColor={outerbackgroundColor}
      backgroundAlignment={backgroundAlignment}
      anchorId={anchorLink}
    >
      <Wrapper>
        <Container
          itemsLayoutType={itemsLayoutType}
          isBorderBottom={isBorderBottom}
          layoutType={layoutType}
        >
          <HeadContainer align={align} layoutType={layoutType} itemsLayoutType={itemsLayoutType}>
            {layoutType === 'row' && renderImage()}
            {itemsLayoutType === 'under' ? (
              renderHeadText()
            ) : (
              <OnSideContainer layoutType={layoutType}>
                {renderHeadText()}
                {hasItems && renderGrid()}
                {linkConfig.url !== '' && (
                  <StyledLinkComponent
                    title={linkLabel}
                    url={linkConfig.url}
                    fontColor={fontColor}
                    isExternal={linkConfig.isExternal}
                  />
                )}
              </OnSideContainer>
            )}
          </HeadContainer>

          {itemsLayoutType === 'under' && (
            <>
              {hasItems && renderGrid()}
              {linkConfig.url !== '' && (
                <StyledLinkComponent
                  title={linkLabel}
                  url={linkConfig.url}
                  fontColor={fontColor}
                  isExternal={linkConfig.isExternal}
                />
              )}
            </>
          )}

          {topAnchorLink && (
            <StyledJumpLink href="#" title="Navigate back to the top of the page">
              Back to top ↑
            </StyledJumpLink>
          )}
        </Container>
      </Wrapper>
    </Section>
  );
};

export default MasterDispatch;
