import React from 'react';
import Text from '@components/text';
import Input from '@components/input';
import { HelpText, InputContainer } from './input-group.styled';

export interface InputProps {
  label: string;
  helpText: string;
  size?: 'small';
  placeholder?: string;
  onChange?: (value: string) => void;
  className?: string;
  value?: string | number;
  name?: string;
  disabled?: boolean;
  isInvalid?: boolean;
}

const InputGroup = ({
  placeholder,
  size,
  className,
  onChange,
  value = '',
  name,
  disabled = false,
  isInvalid = false,
  helpText,
  label,
}: InputProps): JSX.Element => (
  <InputContainer>
    {label && <Text type="label" fontSize="extraSmall" stringText={label} margin="none" />}
    <Input
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isInvalid={isInvalid}
      disabled={disabled}
      className={className}
      size={size}
    />
    {isInvalid && <HelpText>{helpText}</HelpText>}
  </InputContainer>
);
export default InputGroup;
