import { css } from 'styled-components';
import { StyledSVG } from '@components/svg-icons/svg-icon.styled';
import { generateScales } from '@helpers/generate-media-queries';

interface ButtonProps {
  variant?: string;
  outlined?: boolean;
  size?: string;
  disabled?: boolean;
}

export const Button = css<ButtonProps>`
  // base button
  ${({ theme }) => css`
    text-decoration: none;
    font-family: ${theme.fontFamily.text};
    font-weight: 500;
    font-size: ${theme.fontSize.body.desktop};
    cursor: pointer;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    border-width: ${theme.borderWidths.ui};
    border-style: solid;
  `}

  // dark variant
  ${({ theme, variant, outlined }) =>
    variant === 'dark' &&
    css`
      border-color: ${theme.colors.darkPurple};
      background: ${theme.colors.darkPurple};
      color: ${theme.colors.white};

      :hover {
        color: ${theme.colors.darkPurple};
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.white};
      }

      :focus {
        outline: 4px solid ${theme.colors.white}44;
      }

      // icon
      ${StyledSVG} {
        stroke: ${theme.colors.primary};
      }

      // dark outlined
      ${outlined &&
      css`
        background-color: transparent;
        color: ${theme.colors.darkPurple};

        :hover {
          color: ${theme.colors.white};
          background-color: ${theme.colors.darkPurple};
          border-color: ${theme.colors.darkPurple};
        }
      `}
    `}

      // accent variant
  ${({ theme, variant, outlined }) =>
    variant === 'accent' &&
    css`
      border-color: ${theme.colors.accent};
      background: ${theme.colors.accent};
      color: ${theme.colors.darkPurple};

      :hover {
        color: ${theme.colors.white};
        background-color: ${theme.colors.primary};
        border-color: ${theme.colors.primary};
      }

      :focus {
        outline: 4px solid ${theme.colors.darkPurple}44;
      }

      // icon
      ${StyledSVG} {
        stroke: ${theme.colors.primary};
      }

      // dark outlined
      ${outlined &&
      css`
        background-color: transparent;
        color: ${theme.colors.dark};

        :hover {
          color: ${theme.colors.white};
          background-color: ${theme.colors.dark};
          border-color: ${theme.colors.dark};
        }
      `}
    `}

    // white variant
    ${({ theme, variant, outlined }) =>
    variant === 'white' &&
    css`
      border-color: ${theme.colors.white};
      background: ${theme.colors.white};
      color: ${theme.colors.dark};

      :hover {
        color: ${theme.colors.white};
        background-color: ${theme.colors.dark};
        border-color: ${theme.colors.dark};
      }

      :focus {
        outline: 4px solid ${theme.colors.dark}44;
      }

      // icon
      ${StyledSVG} {
        stroke: ${theme.colors.primary};
      }

      // white outlined
      ${outlined &&
      css`
        background-color: transparent;
        color: ${theme.colors.white};

        // icon
        ${StyledSVG} {
          stroke: ${theme.colors.dark};
        }

        :hover {
          color: ${theme.colors.primary};
          background-color: ${theme.colors.white};
          border-color: ${theme.colors.white};
        }
      `}
    `}

    // primary variant
    ${({ theme, variant, outlined }) =>
    variant === 'primary' &&
    css`
      border-color: ${theme.colors.primary};
      background: ${theme.colors.primary};
      color: ${theme.colors.white};

      :hover {
        color: ${theme.colors.dark};
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.white};
      }

      :focus {
        outline: 4px solid ${theme.colors.white}44;
      }

      // icon
      ${StyledSVG} {
        stroke: ${theme.colors.dark};
      }

      // primary outlined
      ${outlined &&
      css`
        background-color: transparent;
        color: ${theme.colors.primary};

        // icon
        ${StyledSVG} {
          stroke: ${theme.colors.dark};
        }

        :hover {
          color: ${theme.colors.white};
          background-color: ${theme.colors.primary};
          border-color: ${theme.colors.primary};
        }
      `}
    `}

  ${({ theme, disabled }) => css`
    ${disabled &&
    css`
      background: ${theme.colors.muted} !important;
      border-color: ${theme.colors.muted} !important;
      color: ${theme.colors.white} !important;
      cursor: auto !important;

      &:hover {
        color: ${theme.colors.white} !important;
      }
    `}
  `}
`;

export const LargeButton = css`
  ${Button}

  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return `
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      ${generateScales('border-radius', '1.5rem', '1.5625rem', theme)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('height', '3rem', '3.25rem', theme)}
    `;
  }}
`;

export const MediumButton = css`
  ${Button}

  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.md;

    return `
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('height', mobile.height, desktop.height, theme)}
      `;
  }}
`;

export const SmallButton = css`
  ${Button}

  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.sm;

    return `
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('height', mobile.height, desktop.height, theme)}
      `;
  }}
`;
