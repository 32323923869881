import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import getLinkConfig from '@helpers/get-link-config';
import { ImageProps } from '@components/image';
import { useTheme } from 'styled-components';
import { NotificationContainer, Notification } from './notification-banner.styled';

interface NotificationType {
  notificationMessage?: string;
  notificationIcon?: ImageProps;
  notificationUrl: {
    url?: string;
    target?: string;
  };
  isNotificationDismissible?: boolean;
}

const NotificationBanner = ({
  notificationMessage = '',
  notificationIcon,
  notificationUrl,
  isNotificationDismissible = true,
}: NotificationType): JSX.Element => {
  const theme = useTheme();
  const notificationUrlConfig = getLinkConfig(notificationUrl);
  const cookieName = 'homepageNotification';
  const [hasVisited, setHasVisited] = useState(false);
  const hasMessage = notificationMessage && notificationMessage.length > 0;
  const hasUrl = notificationUrl?.url && notificationUrl.url.length > 0;
  const isNotificationVisible =
    (hasMessage && hasUrl && !hasVisited) || isNotificationDismissible === false;

  useEffect(() => {
    const cookieVal = Cookies.get(cookieName);
    setHasVisited(notificationUrlConfig.url === cookieVal);
  }, [cookieName, notificationUrlConfig.url]);

  const setCookieOnClick = () => {
    Cookies.set(cookieName, notificationUrlConfig.url);
    setHasVisited(true);
  };

  return isNotificationVisible ? (
    <NotificationContainer onClick={setCookieOnClick}>
      <Notification
        type="button"
        url={notificationUrlConfig.url}
        title={notificationMessage}
        isExternal={notificationUrlConfig.isExternal}
        target={notificationUrl?.target}
        arrowSize={20}
        icon={notificationIcon}
        arrowFill="accentSecondary"
        arrowStroke={theme.colors.primary}
        arrowContainerStroke={theme.colors.primary}
      />
    </NotificationContainer>
  ) : (
    <></>
  );
};

export default NotificationBanner;
