import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import Grid from '@components/grid';

export const StyledGrid = styled(Grid)`
  ${({ theme }) => css`
    ${generateScales('grid-gap', '1.5rem', '4rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: flex;
      flex-wrap: wrap;
      ${generateScales('row-gap', '1.5rem', '3rem', theme)}
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales('row-gap', '2rem', '4rem', theme)}
    margin: 0 auto;
    width: 100%;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      text-align: left;
    }
  `}
`;

export const TitleContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales('row-gap', '1rem', '1.5rem', theme)}
    margin: 0 auto;
    max-width: 40em;
    width: 100%;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      text-align: left;
    }
  `}
`;

export const ItemContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    column-gap: 1rem;
    max-width: 24.5rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 0.75rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: row;
      max-width: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: 100%;
      flex-direction: row;
      column-gap: 0.75rem;
      row-gap: 0.25rem;
    }
  `}
`;

export const ItemText = styled.div`
  ${({ theme }) => css`
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: block;
      max-width: 100%;
    }
  `}
`;
