import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import Image from '@components/image';

export const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 2rem 0;
`;

export const InnerContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    background-color: ${theme.backgroundColors.white};
    border-radius: ${theme.borderRadius.small};
    box-shadow: ${theme.shadows.normal};
    z-index: ${theme.zIndex.layeredContent};
    ${generateScales('padding-top', '3.5rem', '5rem', theme)};
    ${generateScales('padding-bottom', '3.5rem', '4rem', theme)};
    ${generateScales('padding-left', '1rem', '3.25rem', theme)};
    ${generateScales('padding-right', '1rem', '3.25rem', theme)};
  `}
`;

export const BackgroundContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    background: radial-gradient(
      at 40% top,
      ${theme.colors.accentSecondary} 0%,
      ${theme.colors.lightBlue} 50%,
      ${theme.colors.secondary} 85%,
      ${theme.colors.primary} 100%
    );
    border-radius: ${theme.borderRadius.normal};
    width: 90%;
    min-height: calc(100% + 4rem);
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: ${theme.zIndex.layeredGraphic};

    @media (max-width: ${theme.breakpoints.md}) {
      min-height: 108%;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      min-height: 105%;
    }
  `}
`;

export const Title = styled(Text)`
  text-align: center;
  margin-bottom: 0.75rem;
`;

export const Description = styled(Text)`
  ${({ theme }) => css`
    display: block;
    ${generateScales('margin-bottom', '3.5rem', '5rem', theme)};
    text-align: center;
    max-width: 45rem;
  `}
`;

export const StyledGrid = styled.div<{ rowNo: number }>`
  ${({ theme, rowNo = '3' }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(${rowNo}, 1fr);
    ${generateScales('column-gap', '1.5rem', '3rem', theme)};
    /* column-gap: 4rem; */
    row-gap: 4rem;

    @media (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: 1fr;
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    column-gap: 1.25rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      column-gap: 1rem;
      max-width: 100%;
    }
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
`;

export const Icon = styled(Image)`
  width: 3rem;
  height: 3rem;
`;

export const ItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;
