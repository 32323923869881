import styled, { css } from 'styled-components';
import Image from '@components/image';
import { generateScales } from '@helpers/generate-media-queries';
import Text from '@components/text';
import LinkComponent from '@components/link';

export const Badge = styled.div`
  ${({ theme }) => css`
    position: absolute;
    ${generateScales('top', '1rem', '1.5rem', theme)}
    ${generateScales('right', '1rem', '1.5rem', theme)}
    background-color: ${theme.colors.accent};
    font-weight: 600;
    text-transform: uppercase;
    color: ${theme.colors.black};
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    ${generateScales('font-size', '0.75rem', '.875rem', theme)}
    letter-spacing: 0.025rem;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
    margin-left: auto;
    margin-right: auto;
    align-items: stretch;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      column-gap: 1.5rem;
      row-gap: 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 1rem;
    }
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 41rem;
    width: 100%;
    text-align: center;

    ${generateScales('margin-bottom', '1.25rem', '2.5rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      text-align: left;
    }
  `}
`;

export const PolygonBackground = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: calc(100% - 10rem);
    left: 50%;
    transform: translateX(-50%) rotate(-15deg);
    width: 200%;
    height: 100%;
    background-color: ${theme.colors.secondary};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      bottom: calc(100% - 7.5rem);
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      bottom: calc(100% - 5rem);
      left: 15%;
      transform: translateX(-50%) rotate(-25deg);
    }
  `}
`;

export const CopyBlock = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const TextContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    z-index: 1;
    position: relative;
    padding: 0;
    text-align: left;
    ${generateScales('padding', '1rem', '1.5rem', theme)}
    ${generateScales('padding-top', '0.5rem', '1rem', theme)}
    ${generateScales('padding-bottom', '1rem', '2rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
      padding-top: 0.5rem;
    }
  `}
`;

export const StyledImage = styled(Image)`
  height: 70%;
  transition: transform 0.3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%) translateX(calc(-50% + 2rem));
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    margin-left: 1rem;
    position: relative;
    height: 10rem;
    ${generateScales('height', '4rem', '8rem', theme)}
    width: calc(100% - 1rem);
    overflow: hidden;
    border-top-left-radius: 4rem;
    ${generateScales('border-top-left-radius', '2rem', '4rem', theme)}
    border-bottom-left-radius: 4rem;
    ${generateScales('border-bottom-left-radius', '2rem', '4rem', theme)}
    ${generateScales('margin-top', '0rem', '-1rem', theme)}

    &::before {
      display: block;
      content: '';
      height: 8rem;
      ${generateScales('height', '4rem', '8rem', theme)}
      width: 8rem;
      ${generateScales('width', '4rem', '8rem', theme)}
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 4rem;
      ${generateScales('border-radius', '2rem', '4rem', theme)}
      background-color: #b2ffce;
    }
  `}
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden;
  filter: drop-shadow(0 1mm 2mm rgba(0, 0, 0, 0.2));

  &:hover {
    ${StyledImage} {
      transform: translateY(-50%) translateX(calc(-50% + 6rem));
    }
  }

  ${({ theme }) => css`
    background-color: ${theme.backgroundColors.light};
    border-radius: ${theme.borderRadius.small};
  `};
`;

export const StyledLink = styled(LinkComponent)`
  ${({ theme }) => css`
    margin: 0;
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
  `}
`;
