import React, { useEffect, useState } from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import getLinkConfig from '@helpers/get-link-config';
import Text from '@components/text';
import generateId from '@helpers/generate-id';
import LinkComponent from '@components/link';
import {
  StyledHead,
  HeadContent,
  TextContent,
  ButtonsContainer,
  StyledButton,
  StyledImage,
  TabContent,
  ItemText,
  ImageContainer,
  ItemContent,
} from './tabbed-content.styled';

const experiencesIdGenerator = generateId();

interface LinkProps {
  url?: string;
  uid?: string;
  target?: string;
}

interface TabbedContentItemType {
  tab_label?: string;
  link_label?: string;
  link?: LinkProps;
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  image?: ImageProps;
}

interface ExperiencesProps {
  title?: string;
  description?: RichTextBlock[];
  items: TabbedContentItemType[];
}

const TabbedContent = ({ title, description, items = [] }: ExperiencesProps): JSX.Element => {
  const [viewContent, setViewContent] = useState(items[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [linkConfig, setLinkConfig] = useState(getLinkConfig(viewContent.link));
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setLinkConfig(getLinkConfig(viewContent.link));
  }, [viewContent]);

  return (
    <Section backgroundColor="light">
      <Wrapper>
        <StyledHead>
          <HeadContent>
            <TextContent>
              {!!title && (
                <Text tag="h2" type="title" stringText={title} fontSize="heading2" margin="none" />
              )}
              {!!description?.length && (
                <Text
                  type="subheading"
                  richText={description}
                  fontSize="subheading"
                  margin="none"
                />
              )}
            </TextContent>
            <TabContent>
              <ButtonsContainer>
                {items?.map((item, index) => (
                  <StyledButton
                    type="button"
                    onClick={() => {
                      if (index !== activeIndex) {
                        setAnimate(true);
                        setActiveIndex(index);
                      }
                      setTimeout(() => {
                        setViewContent(item);
                      }, 250);
                    }}
                    isActive={viewContent === item}
                    key={experiencesIdGenerator.next().value}
                  >
                    {item.tab_label}
                  </StyledButton>
                ))}
              </ButtonsContainer>
              <ItemContent>
                <ItemText>
                  <Text
                    tag="h3"
                    type="title"
                    stringText={viewContent.title?.text}
                    fontSize="heading3"
                    margin="none"
                  />
                  <Text
                    type="subheading"
                    richText={viewContent.description?.richText}
                    margin="none"
                  />
                  {linkConfig.url !== '' && (
                    <LinkComponent
                      title={viewContent.link_label || 'Learn More'}
                      url={linkConfig.url}
                      isExternal={linkConfig.isExternal}
                    />
                  )}
                </ItemText>
                <ImageContainer animate={animate} onAnimationEnd={() => setAnimate(false)}>
                  {!!(viewContent?.image?.gatsbyImageData || viewContent?.image?.url) && (
                    <StyledImage
                      gatsbyImageData={viewContent?.image.gatsbyImageData}
                      url={viewContent?.image.url}
                      alt={viewContent?.image.alt || ''}
                      objectFit="contain"
                    />
                  )}
                </ImageContainer>
              </ItemContent>
            </TabContent>
          </HeadContent>
        </StyledHead>
      </Wrapper>
    </Section>
  );
};

export default TabbedContent;
