import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Wrapper from '@components/wrapper.styled';
import { defaultTheme } from '@styles/theme.styled';
import Section from '@components/section';
import Text from '@components/text';
import { Container } from './text-section.styled';

export interface TextSectionProps {
  title?: string;
  description?: RichTextBlock[];
  align?: string;
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
}

const TextSection = ({
  title,
  description,
  align = 'center',
  backgroundColor = 'transparent',
}: TextSectionProps): JSX.Element => (
  <Section backgroundColor={backgroundColor}>
    <Wrapper>
      <Container align={align} backgroundColor={backgroundColor}>
        {!!title && (
          <Text type="title" tag="h2" fontSize="heading2" stringText={title} margin="md" />
        )}
        {!!description && <Text richText={description} type="subheading" />}
      </Container>
    </Wrapper>
  </Section>
);

export default TextSection;
